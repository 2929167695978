import AddBonus from "../../Components/Bonus/AddBonus/AddBonus"

const AddBonusPage = () => {
  return (
    <>
        <AddBonus />
    </>
  )
}

export default AddBonusPage