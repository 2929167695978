import Withdrowals from '../../Components/Withdrowals/Withdrowals'

const WithdrowalsPage = () => {
  return (
    <>
        <Withdrowals />
    </>
  )
}

export default WithdrowalsPage