import React from 'react'
import Bonus from '../../Components/Bonus/Bonus'

const BonusPage = () => {
  return (
    <>
      <Bonus />
    </>
  )
}

export default BonusPage