import Settlements from "../../Components/Settlements/Settlements"

const SettlementsPage = () => {
  return (
    <>
      <Settlements />
    </>
  )
}

export default SettlementsPage