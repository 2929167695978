import React from 'react'
import UserOperations from '../../Components/UserOperations/UserOperations'

const UserOperationsPage = () => {
  return (
    <>
        <UserOperations />
    </>
  )
}

export default UserOperationsPage