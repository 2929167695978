import React from 'react'
import Dashboard from '../../Components/Home/Dashboard/Dashboard'

const HomePage = () => {
  return (
    <>
      <Dashboard />
    </>
  )
}

export default HomePage