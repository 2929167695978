import React from 'react'
import AddNoteBook from '../../Components/NoteBook/AddNoteBook/AddNoteBook'

const AddNoteBookPage = () => {
  return (
    <>
      <AddNoteBook />
    </>
  )
}

export default AddNoteBookPage