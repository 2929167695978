import NoteBookDetails from '../../Components/NoteBook/NoteBookDetails/NoteBookDetails'

const NoteBookPage = () => {
  return (
    <>
      <NoteBookDetails />
    </>
  )
}

export default NoteBookPage