import Supervisors from "../../Components/Supervisors/Supervisors"

const SupervisorsPage = () => {
  return (
    <>
      <Supervisors />
    </>
  )
}

export default SupervisorsPage