import React from 'react'
import AddDeduction from '../../Components/Deductions/AddDeduction/AddDeduction'

const AddDeductionPage = () => {
  return (
    <>
      <AddDeduction />
    </>
  )
}

export default AddDeductionPage