import Contract from "../../Components/Contracts/Contract/Contract"

const ContractPage = () => {
  return (
    <>
      <Contract />
    </>
  )
}

export default ContractPage