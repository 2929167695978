import React from 'react'
import Supervisor from '../../Components/Supervisors/Supervisor/Supervisor'

const SupervisorPage = () => {
  return (
    <>
      <Supervisor />
    </>
  )
}

export default SupervisorPage