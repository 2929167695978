import React from 'react'
import Deductions from '../../Components/Deductions/Deductions'

const DeductionsPage = () => {
  return (
    <>
      <Deductions />
    </>
  )
}

export default DeductionsPage