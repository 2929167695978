import Expenses from "../../Components/Expenses/Expenses";

const ExpensesPage = () => {
  return (
    <>
      <Expenses />
    </>
  );
};

export default ExpensesPage;
