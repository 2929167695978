import Search from '../../Components/Search/Search'

const SearchPage = () => {
  return (
    <>
        <Search />
    </>
  )
}

export default SearchPage