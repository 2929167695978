import React from 'react'
import AddPosition from '../../Components/Auth/AddPosition/AddPosition'

const AddPositionPage = () => {
  return (
    <>
      <AddPosition />
    </>
  )
}

export default AddPositionPage