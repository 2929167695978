import React from 'react'
import DeliverNoteBook from '../../Components/NoteBook/DeliverNoteBook/DeliverNoteBook'

const DeliverNoteBookPage = () => {
  return (
    <>
      <DeliverNoteBook />
    </>
  )
}

export default DeliverNoteBookPage