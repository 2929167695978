import ArchieveContract from '../../Components/ArchieveContract/ArchieveContract'

const AddContractToArchivePage = () => {
  return (
    <>
        <ArchieveContract />
    </>
  )
}

export default AddContractToArchivePage