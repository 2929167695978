import ArchievePage from "../../Components/ArchieveContract/ArchievePage/ArchievePage"

const ArchieveContractPage = () => {
  return (
    <>
        <ArchievePage />
    </>
  )
}

export default ArchieveContractPage