import ViewNoteBooks from '../../Components/NoteBook/ViewNoteBooks/ViewNoteBooks'

const AllNoteBooksPage = () => {
  return (
    <>
      <ViewNoteBooks />
    </>
  )
}

export default AllNoteBooksPage